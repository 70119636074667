<template>
  <v-sheet class="pa-2 text-center amp-blue mt-0">
    <a href="https://kp4cd.org/" target="_blank">
      {{ base_text }}{{ version ? ' | Version: ' + version : '' }}
    </a>
  </v-sheet>
</template>

<script>
export default {
  name: 'PageFooter',
  data() {
    return {
      base_text: 'Powered by the HuGeAMP   |  ACCELERATING MEDICINES PARTNERSHIP and AMP are registered service ' +
        'marks of the U.S. Department of Health and Human Service',
      version: process.env.VUE_APP_VERSION
    };
  }
};
</script>

<style scoped>

.amp-blue {
  background-color: #0097d6;
}
a {
  color: white !important;
  font-size: 1rem;
  text-decoration: none;
}

a:hover {
  color: lawngreen !important;
}
</style>
