<script>
import ColorHash from 'color-hash';
import { formatVariant } from '@/util/util';

export default {
  name: 'VariantLabel',
  props: {
    variant: { type: String, required: true },
    abbrev: { type: Boolean, default: true },
    build: { type: String, default: '' },
    size: { type: String, default: '' } // can be "x-small", "small", "large", "x-large
  },
  created() {
    this.colorHasher = new ColorHash({ lightness: [0.7, 0.8, 0.7] });
  },
  methods: {
    formatVariant
  },
  computed: {
    chipStyle() {
      const textColor = this.colorHasher.hex(this.variant);
      return {
        color: textColor,
        backgroundColor: 'transparent',
        fontWeight: 'bold',
        fontSize: '16px'
      };
    },
    chipAttributes() {
      const attr = {};
      // attr.color = this.colorHasher.hex(this.variant);

      if (this.size) {
        attr[this.size] = true;
      }

      return attr;
    }
  }
};
</script>

<style scoped>

</style>

<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-chip v-bind="{ ...attrs, ...chipAttributes }" v-on="on" label :style="chipStyle">
        <v-icon :style="chipStyle" class="ml-n3 mr-1 px-0">
          mdi-square
        </v-icon>
        {{ formatVariant(variant, 25) }}
      </v-chip>
    </template>
    <span>
      {{ formatVariant(variant) }}
      <div v-if="build">
        <span>Build: {{ build }}</span>
      </div>
    </span>
  </v-tooltip>
</template>
