<template>
  <v-sheet outlined :style="panelStyle" class="ma-0 px-2 pt-2 pb-0" elevation="0">

    <v-row class="my-0 py-0">
      <v-col cols="3" class="my-0 py-0"><h4 class="text-right my-0 py-0">Y-axis:</h4></v-col>
      <v-col class="my-0 py-0">
        <v-radio-group row mandatory v-model="selectedMCRadio" @change="onMCRadioChange" class="my-0 py-0">
          <v-radio value="t1">
              <template v-slot:label>Marginal -log<sub>10</sub>p</template>
          </v-radio>
          <v-radio value="t2">
              <template v-slot:label>Conditional -log<sub>10</sub>p</template>
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row class="my-0 py-0">
      <v-col cols="3" class="my-0 py-0"><h4 class="text-right my-0 py-0">LD Reference:</h4></v-col>
      <v-col class="my-0 py-0">
        <v-radio-group v-model="selectedLDRadio" @change="onLDRadioChange" class="my-0 py-0">
          <v-radio v-for="(ld_ref, index) in ld_refs" :key="index" :value="ld_ref">
            <template v-slot:label>
              <VariantLabel :variant="ld_ref"/>
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

  </v-sheet>
</template>

<script>
import VariantLabel from '@/components/widgets/VariantLabel.vue';

export default {
  components: { VariantLabel },
  props: { ld_refs: { type: Array, required: true, default: () => [] } },
  data() {
    return {
      selectedLDRadio: this.ld_refs[0],
      selectedMCRadio: 't2',
      marginal: 't1',
      conditional: 't2',
      selectedItem: null,
      panelStyle: {
        width: '500px',
        backgroundColor: '#fafafa',
        borderColor: '#ff3d00',
        borderWidth: '1px',
        borderStyle: 'solid',
      },
    };
  },
  methods: {
    onSelectionChange(value) {
    },
    onMCRadioChange(value) {
      console.log('mcchange:', value);
      this.$emit('mc-changed', value);
    },
    onLDRadioChange(value) {
      this.$emit('ld-ref-changed', value);
    },
  }
};
</script>

<style scoped>
/* The following uses a Vue-specific notation (>>>) to select child components.
   Needed to hide an unused subcomponent of the v-select taking up vertical space. */
>>> .v-text-field__details {
  display: none !important;
}

/* The following uses a Vue-specific notation (>>>) to select child components.
   Needed to hide unused subcomponent of v-radio taking up vertical space. */
>>> .v-messages.theme--light {
  min-height: 0;
  display: none !important;
}

.control-labels {
  font-size: 16px;
  font-weight: bold;
  justify-content: right;
}
</style>
