<script>
import TraitLabel from '@/components/widgets/TraitLabel';
import VariantLabel from '@/components/widgets/VariantLabel.vue';
import StudyLabel from '@/components/widgets/StudyLabel.vue';
import { ANALYSIS_TYPES, COLOR_EFF_POS, COLOR_EFF_NEG, COLOR_EFF_ZERO, COLOR_EFF_NA, ITEMS_PER_PAGE } from '@/constants';

/**
 * A table of colocalization results, as fetched from the colocus `/api/studies/<study_id>/coloc/<coloc_id>/` endpoint format
 *
 * This is a thin wrapper around v-data-table; see vuetify docs for usage of props
 */
export default {
  name: 'ColocResultsTable',
  components: { TraitLabel, VariantLabel, StudyLabel },
  props: {
    // Props that control this component uniquely
    show_trait1: { type: Boolean, default: true },
    show_ensg: { type: Boolean, default: false },
    show_effects: { type: Boolean, default: false },
    abbrev_trait: { type: Boolean, default: false },
    // Thin options whose usage mimics v-data-table
    items: { type: Array, default: () => [] },
    // traitTypes: { type: Array, default: () => [] },
    highlightSignals: { type: Array, default: () => [] },
    options: { type: Object, default: () => ({}) },
    'server-items-length': { type: Number },
    loading: { type: Boolean, default: true },
    // addPlot: null,
    showAddPlotIcon: { type: Boolean, default: false },
  },
  created() {
    this.effColors = {
      POS: COLOR_EFF_POS,
      NEG: COLOR_EFF_NEG,
      ZERO: COLOR_EFF_ZERO,
      NA: COLOR_EFF_NA
    };
  },
  data() {
    return {
      table_options: this.options,
    };
  },
  computed: {
    analysisTypes() {
      const types = new Set();
      for (const i of this.items) {
        types.add(i.signal1.analysis.analysis_type);
        types.add(i.signal2.analysis.analysis_type);
      }
      const arr = Array.from(types);
      arr.sort();
      return arr;
    },
    table_headers() {
      const base = [];
      // const has_gwas = this.traitTypes.includes(ANALYSIS_TYPES.GWAS);
      const has_eqtl = this.analysisTypes.includes(ANALYSIS_TYPES.EQTL);

      if (this.showAddPlotIcon) base.push({ text: 'Add plots', value: 'actions', sortable: false });

      base.push({ text: 'Study 1', sortable: true, value: 'signal1.analysis.study.uuid' });

      if (this.show_trait1) {
        // Some views treat trait 1 as fixed, and labeled somewhere else, so it is not needed in the table
        // if (has_eqtl) {
        //   base.unshift({ text: 'Trait 1 Tissue', sortable: true, value: 'signal1.analysis.trait.tissue' });
        // }
        base.push({ text: 'Trait 1', sortable: true, value: 'signal1.analysis.trait.uuid' });
      }

      base.push({ text: 'Study 2', sortable: true, value: 'signal2.analysis.study.uuid' });

      if (has_eqtl) {
        base.push({ text: 'Trait 2', sortable: true, value: 'signal2.analysis.trait.uuid' });
        base.push({ text: 'Trait 2 Type', sortable: false, value: 'signal2.analysis.trait.biomarker_type' });
        if (this.show_ensg) {
          base.push({ text: 'Trait 2 ENSG', sortable: true, value: 'signal2.analysis.trait.gene.ens_id' });
        }
      } else {
        base.push({ text: 'Trait 2', sortable: true, value: 'signal2.analysis.trait.uuid' });
      }

      if (has_eqtl) {
        base.push({ text: 'Trait 2 Tissue', sortable: true, value: 'signal2.analysis.tissue' });
      }

      base.push(...[
        { text: 'Trait 1 Variant', sortable: true, value: 'signal1.lead_variant.vid' },
        { text: 'Trait 2 Variant', sortable: true, value: 'signal2.lead_variant.vid' },
        { text: 'Trait 1 -log10p', sortable: true, value: 'signal1.neg_log_p' },
        { text: 'Trait 2 -log10p', sortable: true, value: 'signal2.neg_log_p' },
        { text: 'H3', sortable: true, value: 'coloc_h3' },
        { text: 'H4', sortable: true, value: 'coloc_h4' },
        { text: 'R2', sortable: true, value: 'r2' },
        { text: '# coloc between traits', sortable: true, value: 'n_coloc_between_traits', width: '20px' },
        { text: 'Effect Direction Concordance', align: 'center', sortable: false, value: 'cross_signal.effect', width: '140px' }
      ]);

      if (this.show_effects) {
        base.push(...[
          { text: 'Trait 1 Marginal Effect', sortable: true, value: 'signal1.effect_marg' },
          { text: 'Trait 2 Marginal Effect', sortable: true, value: 'signal2.effect_marg' },
          { text: 'Trait 1 Cond Effect', sortable: true, value: 'signal1.effect_cond' },
          { text: 'Trait 2 Cond Effect', sortable: true, value: 'signal2.effect_cond' },
          { text: 'Effect Flipped Marg ↔ Cond', sortable: true, value: 'marg_cond_flip' }
        ]);
      }

      return base;
    },
    directionOfEffectBetweenTraits() {
      const results = {};
      for (const i of this.items) {
        results[i.uuid] = this.getEffectDirectBetweenTraits(i.cross_signal.effect);
      }
      return results;
    }
  },
  watch: {
    table_options(value) {
      this.$emit('update:options', value);
    }
  },
  methods: {
    ITEMS_PER_PAGE() {
      return ITEMS_PER_PAGE;
    },
    onAddPlotIconClick(item) {
      this.$emit('add-plots-from-icon', item);
    },
    onRowClick(item) {
      this.$emit('row-click', item);
    },
    rowClass(item) {
      if (this.highlightSignals.length) {
        const sameSignal =
          (this.highlightSignals[0].uuid === item.signal1.uuid) &&
          (this.highlightSignals[1].uuid === item.signal2.uuid);

        if (sameSignal) {
          return 'signal-row';
        }
      }
    },
    formatEffect(s) {
      if (s) {
        if (s > 0) {
          return '+';
        } else if (s === 0) {
          return '0';
        } else {
          return '-';
        }
      } else {
        return s;
      }
    },
    effectColor(beta) {
      if (beta > 0) {
        return COLOR_EFF_POS;
      } else if (beta < 0) {
        return COLOR_EFF_NEG;
      } else if (beta === 0) {
        return COLOR_EFF_ZERO;
      } else {
        return COLOR_EFF_NA;
      }
    },
    containsNullOrNaN(arr) {
      return arr.some((sub) => {
        if (!Array.isArray(sub)) {
          throw new Error('Expected an array of arrays.');
        }
        return sub.some((v) => v === null || Number.isNaN(v));
      });
    },
    getEffectDirectBetweenTraits(effects) {
      let discord = false;
      let direction = null;
      let hasMissing = false;

      for (let i = 0; i < effects.length; i++) {
        for (let j = 0; j < effects[i].length; j++) {
          if (effects[i][j] === null || Number.isNaN(effects[i][j])) {
            hasMissing = true;
            discord = false;
            effects[i][j] = NaN;
          }
        }
      }

      const v1 = effects[0][0] * effects[0][1];
      const v2 = effects[1][0] * effects[1][1];
      const d1 = v1 > 0;
      const d2 = v2 > 0;

      if (hasMissing) {
        discord = false;
        if (Number.isNaN(v1) && Number.isNaN(v2)) {
          direction = null;
        } else {
          if (Number.isNaN(v1)) {
            direction = d2 > 0 ? '+' : '-';
          } else {
            direction = d1 > 0 ? '+' : '-';
          }
        }
      } else {
        discord = d1 !== d2;
        direction = d1 ? '+' : '-';
      }

      return {
        discord,
        direction,
        hasMissing
      };
    }
  }
};
</script>

<style>
  .signal-row {
    font-weight: bold;
    background: aliceblue;
  }

  .v-data-footer .v-btn {
    margin-top: 5px;
  }

  .v-data-footer .v-btn .v-icon {
    font-size: 30px !important;
  }

  .effect-table {
    border-collapse: collapse;
    border-style: hidden;
    width: 2.5rem;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .effect-table td {
    border: 1px solid black;
    text-align: center;
  }

  .effect-table td pre {
    font-size: 0.8rem;
  }

  .ensg {
    font-size: 0.75rem;
  }

  div.v-data-table tbody tr {
    cursor: pointer;
  }
</style>

<template>
  <v-data-table
    ref="coloc-results-data-table"
    :headers="table_headers"
    :items="items"
    :item-class="rowClass"
    :options.sync="table_options"
    :server-items-length="serverItemsLength"
    :loading="loading"
    @click:row="onRowClick"
    multi-sort
    fixed-header
    height="82vh"
    :footer-props="{
      'items-per-page-options': [ITEMS_PER_PAGE()],
      'next-icon': 'mdi-arrow-right',
      'prev-icon': 'mdi-arrow-left'
    }"
    @update:page="() => {
      const dataTableWrapper = this.$refs['coloc-results-data-table'].$el.querySelector('.v-data-table__wrapper');
      if (dataTableWrapper) {
        dataTableWrapper.scrollTop = 0;
      }
    }"
  >

    <template v-if="showAddPlotIcon" v-slot:[`item.actions`]="{ item }">
      <v-icon @click.native.stop="onAddPlotIconClick(item)">
        mdi-image-plus-outline
      </v-icon>
    </template>

    <template #[`item.signal1.analysis.study.uuid`]="{item}">
      <StudyLabel :study_name="item.signal1.analysis.study.uuid" />
    </template>

    <template #[`item.signal1.analysis.trait.uuid`]="{item}">
<!--  The @click.native.stop is to prevent the click event from bubbling up to the row click event.-->
      <router-link @click.native.stop :to="{name: 'one_analysis_all_signals', params: { analysis_id: item.signal1.analysis.uuid}}">
        <trait-label :trait_data="item.signal1.analysis.trait" :abbrev="abbrev_trait"/>
      </router-link>
    </template>

    <template #[`item.signal2.analysis.study.uuid`]="{item}">
      <StudyLabel :study_name="item.signal2.analysis.study.uuid" />
    </template>

    <template #[`item.signal2.analysis.trait.uuid`]="{item}">
<!--      <router-link @click.native.stop :to="{name: 'one_analysis_all_signals', params: { analysis_id: item.signal2.analysis.uuid}}">-->
<!--        <trait-label :trait_data="item.signal2.analysis.trait" :abbrev="abbrev_trait"/>-->
<!--      </router-link>-->
      <trait-label :trait_data="item.signal2.analysis.trait" :abbrev="abbrev_trait"/>
    </template>

    <template #[`item.signal2.analysis.trait.biomarker_type`]="{item}">
      <span>
        {{ item.signal2.analysis.trait.biomarker_type.replace("-expression", "") }}
      </span>
    </template>

    <template #[`item.signal2.analysis.trait.gene.ens_id`]="{item}">
      <div v-if="item.signal2.analysis.trait.exon">
        <span class="ensg">
          {{ item.signal2.analysis.trait.exon.ens_id }}
        </span>
      </div>
      <div v-else>
        <span class="ensg">
          {{ item.signal2.analysis.trait.gene.ens_id }}
        </span>
      </div>
    </template>

<!--    <template #[`item.signal1.lead_variant_nearest_gene`]="{item}">-->
<!--      <router-link :to="{name: 'one_trait_summarize_region', params: { study_id: item.analysis.uuid, trait_id: item.signal1.trait.uuid}, query: {gene: item.signal1.lead_variant_nearest_gene}}">-->
<!--        {{ item.signal1.lead_variant_nearest_gene }}-->
<!--      </router-link>-->
<!--    </template>-->

    <template #[`item.signal1.lead_variant.vid`]="{item}">
      <VariantLabel :variant="item.signal1.lead_variant.vid" :build="item.signal1.analysis.genome_build" />
    </template>

    <template #[`item.signal2.lead_variant.vid`]="{item}">
      <VariantLabel :variant="item.signal2.lead_variant.vid" :build="item.signal2.analysis.genome_build" />
    </template>

    <template #[`item.signal1.neg_log_p`]="{item}">
      {{ (+item.signal1.neg_log_p).toFixed(2) }}
    </template>

    <template #[`item.signal2.neg_log_p`]="{item}">
      {{ (+item.signal2.neg_log_p).toFixed(2) }}
    </template>

    <template #[`item.coloc_h3`]="{item}">
      {{ item.coloc_h3.toFixed(2) }}
    </template>

    <template #[`item.coloc_h4`]="{item}">
      {{ item.coloc_h4.toFixed(2) }}
    </template>

    <template #[`item.signal1.effect_cond`]="{item}">
      {{ item.signal1.effect_cond.toFixed(2) }}
    </template>

    <template #[`item.signal2.effect_cond`]="{item}">
      {{ item.signal2.effect_cond.toFixed(2) }}
    </template>

    <template #[`item.signal1.effect_marg`]="{item}">
      {{ item.signal1.effect_marg.toFixed(2) }}
    </template>

    <template #[`item.signal2.effect_marg`]="{item}">
      {{ item.signal2.effect_marg.toFixed(2) }}
    </template>

    <template #[`item.marg_cond_flip`]="{item}">
      {{ +item.marg_cond_flip }}
    </template>

    <template #[`item.r2`]="{item}">
      {{ item.r2.toFixed(2) }}
    </template>

    <template #[`item.cross_signal.effect`]="{item}">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <template v-if="directionOfEffectBetweenTraits[item.uuid].discord">
            <span v-bind="attrs" v-on="on" :style="{ 'color': 'DarkOrange', 'font-size': '22pt' }">
              ⚠
            </span>
          </template>
          <template v-else>
            <span v-if="directionOfEffectBetweenTraits[item.uuid].direction === '+'" v-bind="attrs" v-on="on" :style="{ 'color': 'red' }">
              {{ directionOfEffectBetweenTraits[item.uuid].hasMissing ? 'MISS' : 'CON' }}
            </span>
            <span v-else-if="directionOfEffectBetweenTraits[item.uuid].direction === '-'" v-bind="attrs" v-on="on" :style="{ 'color': 'blue' }">
              {{ directionOfEffectBetweenTraits[item.uuid].hasMissing ? 'MISS' : 'DIS' }}
            </span>
            <span v-else></span>
          </template>
        </template>
        <span v-if="directionOfEffectBetweenTraits[item.uuid].discord" class="d-block text-center text-body-1">Effects are inconsistent</span>
        <span v-else-if="directionOfEffectBetweenTraits[item.uuid].hasMissing" class="d-block text-center text-body-1">At least one effect is missing</span>
        <table class="effect-table">
          <tr>
            <td></td>
            <td class="no-wrap">Trait 1</td>
            <td class="no-wrap">Trait 2</td>
          </tr>
          <tr>
            <td class="no-wrap pr-1">
              Variant 1
            </td>
            <td :style="{ 'background-color': `${effectColor(item.cross_signal.effect[0][0])}` }">
              <pre>{{ formatEffect(item.cross_signal.effect[0][0]) }}</pre>
            </td>
            <td :style="{ 'background-color': `${effectColor(item.cross_signal.effect[0][1])}` }">
              <pre>{{ formatEffect(item.cross_signal.effect[0][1]) }}</pre>
            </td>
          </tr>
          <tr>
            <td class="no-wrap pr-1">
              Variant 2
            </td>
            <td :style="{ 'background-color': `${effectColor(item.cross_signal.effect[1][0])}` }">
              <pre>{{ formatEffect(item.cross_signal.effect[1][0]) }}</pre>
            </td>
            <td :style="{ 'background-color': `${effectColor(item.cross_signal.effect[1][1])}` }">
              <pre>{{ formatEffect(item.cross_signal.effect[1][1]) }}</pre>
            </td>
          </tr>
       </table>
      </v-tooltip>
    </template>

    <template #[`header.cross_signal.effect`]="{header}">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        >{{ header.text }}</span>
        </template>
        <h2>Concordance of Direction of Effect Between Trait 1 and 2</h2><br>
        <span>
          Column denoting whether directions of effect for the two colocalized variants are discordant between the two traits. <br><br>

          There are two colocalized variants, one for each trait. The two colocalized variants have an effect size in the marginal summary statistics for each trait. <br>
          We can build a 2x2 table from these effect sizes: <br><br>

          ⎡ sign(β<sub>1,1</sub>) sign(β<sub>1,2</sub>) ⎤ <br>
          ⎣ sign(β<sub>2,1</sub>) sign(β<sub>2,2</sub>) ⎦ <br><br>

          Where sign(β<sub>1,1</sub>) is the effect direction of trait 1 variant in trait 1's marginal summary statistics, <br>
          sign(β<sub>1,2</sub>) is the effect direction of trait 1 variant in trait 2's marginal summary statistics, etc. <br><br>

          The effect directions for both variants are oriented toward the alternate allele (the second allele in the variant ID).<br><br>

          Discordance occurs when the pattern of effect size directions changes from the first to second variant,
          in other words: <br><br>

          sign(β<sub>1,1</sub>) * sign(β<sub>1,2</sub>) != sign(β<sub>2,1</sub>) * sign(β<sub>2,2</sub>) <br><br>

          Various colors are: <br><br>
          <span :style="{ 'color': `${effColors.POS}` }">Positive effect direction</span><br>
          <span :style="{ 'color': `${effColors.NEG}` }">Negative effect direction</span><br>
          <span :style="{ 'color': `${effColors.ZERO}` }">Effect is zero</span><br>
          <span :style="{ 'color': `${effColors.NA}` }">Effect is missing (NA)</span><br>
        </span>
      </v-tooltip>
    </template>

    <template #[`header.r2`]="{header}">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        >{{ header.text }}</span>
        </template>
        <span>
          Linkage disequilibrium (r2) between the lead variants of the two signals
        </span>
      </v-tooltip>
    </template>

  </v-data-table>
</template>

<style scoped>
.v-tooltip__content {
  background-color: black;
}

.v-data-table::v-deep th {
  font-size: 1rem !important;
}
.v-data-table::v-deep td {
  font-size: 1rem !important;
}

.no-wrap {
  white-space: nowrap;
}

.cursor-plus:hover {
  cursor: cell;
}

.full-width-cell {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
}

</style>
