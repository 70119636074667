<script>

import { normalizeMarker } from 'locuszoom/esm/helpers/parse';

import LzPlot from '@/components/widgets/LzPlot';
import { fetchJson, url, makePlotTitle } from '@/util/util';
// import LocusZoom from 'locuszoom';
import {
  config_to_sources,
  get_compare_layout,
  get_region_layout,
  get_region_sources,
  toggle_trait
} from '@/lz-util/layouts';
import TraitLabel from '@/components/widgets/TraitLabel';
import VariantLabel from '@/components/widgets/VariantLabel';
import ColocResultQuery, { coloc_query_url } from '@/mixins/ColocResultQuery';
import ColocResultsTable from '@/components/widgets/ColocResultsTable';
import { NEARBY_DIST } from '@/constants';
import store from '@/store';
import LDPanel from '@/components/widgets/LDPanel.vue';
import ColorHash from 'color-hash';

function findPlotRegion(pos1, pos2) {
  const min = Math.min(pos1, pos2);
  const max = Math.max(pos1, pos2);
  const between = max - min;

  let start, end;
  if ((max - min) > NEARBY_DIST) {
    // The 1000 is just to nudge it slightly away from axis boundaries
    start = Math.max(1, min - 1000);
    end = max + 1000;
  } else {
    const remain = Math.ceil((NEARBY_DIST - between) / 2);
    start = Math.max(1, min - remain);
    end = max + remain;
  }

  return { start, end };
}

function _table_url(coloc_signal1, coloc_signal2, filter_data) {
  const base = coloc_query_url.bind(this)();
  if (!coloc_signal1) {
    // TODO: how should we handle this, scenarios where table isn't ready at page load etc
    return base;
  }

  const { start, end } = findPlotRegion(
    coloc_signal1.lead_variant.pos,
    coloc_signal2.lead_variant.pos
  );

  const signal1_region = `${coloc_signal1.lead_variant.chrom}:${start}-${end}`;
  const signal2_region = `${coloc_signal2.lead_variant.chrom}:${start}-${end}`;

  base.searchParams.set('signal1_region', signal1_region);
  base.searchParams.set('signal2_region', signal2_region);
  base.searchParams.set('min_h4', filter_data.filter_h4);
  base.searchParams.set('min_r2', filter_data.filter_r2);
  base.searchParams.set('signal1_min_logp', filter_data.filter_trait1_logp);
  base.searchParams.set('signal2_min_logp', filter_data.filter_trait2_logp);

  return base;
}

function getData(coloc_result_uuid, filter_data) {
  // These are two separate queries because, in the event of pagination, it's theoretically possible for THIS signal to not appear in TOP_N_nearby signals response
  const this_result = url`/api/v1/coloc/${coloc_result_uuid}/`;

  return fetchJson(this_result)
    .then((coloc) => {
      const { signal1, signal2 } = coloc;
      const nearby_results = _table_url(signal1, signal2, filter_data);
      // Final return value reflects two queries, one of which depends on the other
      return Promise.all([
        coloc,
        fetchJson(nearby_results),
      ]);
    });
}

// API endpoint uses generic prefix "t1" and "t2" to refer to the pair of results we get from this endpoint
const AXIS_OPTIONS = Object.freeze({ MARGINAL: 't1', CONDITIONAL: 't2' });

// Specify which lead variant to use for LD coloring
const LD_OPTIONS = Object.freeze({ SIGNAL1: 'signal1', SIGNAL2: 'signal2' });

/**
 *
 */
export default {
  name: 'ColocResultRegionView',
  created() {
    this.colorHasher = new ColorHash({ lightness: [0.7, 0.8, 0.7] });
  },
  data() {
    return {
      coloc_result_data: {},
      loading: true,

      option_ldchoice: LD_OPTIONS.SIGNAL1,
      option_yaxis: AXIS_OPTIONS.CONDITIONAL,

      // LocusZoom configuration for page plots
      layout_region: {}, // get_region_layout('gwas', 'qtl', { chr: 4, start: 89034231, end: 91030144 }),
      sources_region: [], // get_region_sources(1),

      layout_compare: {}, // get_compare_layout('gwas', 'qtl', { chr: 4, start: 89034231, end: 91030144 }),

      ld_refs: [],

      // Causes table to be sorted by default first by variant1, then variant2
      // Can't use :sort-by prop on table itself
      // This gets merged with the mixin's table_options
      table_options: {
        sortBy: ['signal1.lead_variant.vid', 'signal2.lead_variant.vid']
      }
    };
  },
  computed: {
    signal1() {
      return this.coloc_result_data.signal1;
    },
    signal2() {
      return this.coloc_result_data.signal2;
    },
    table_url() {
      const { signal1, signal2 } = this.coloc_result_data;
      const filter_data = store.getters.getFilterData;
      return _table_url.bind(this)(signal1, signal2, filter_data);
    },
    table_data_filtered() {
      // The "nearby signals" API would inherently include the result already shown on this page.
      //  Hide that from the list of "other" items.
      return this.table_data.filter((item) => item.uuid !== this.$route.params.coloc_id);
    }
  },
  watch: {
    option_yaxis(new_val, old_val) {
      this.toggleMargCond(new_val, old_val);
    },
    option_ldchoice(value) {
      let variant = this.coloc_result_data[value].lead_variant.vid;
      variant = normalizeMarker(variant);

      this.$refs.lzcompare.callPlot((plot) => plot.applyState({ ldrefvar: variant }));
      this.$refs.lzregion.callPlot((plot) => plot.applyState({ ldrefvar: variant }));
    },
    '$route' (to, from) {
      // This code is entered when a route change occurs, such as loading a new colocalization result
      // The component is re-used when such a route change happens and the route uses the same component
      if (this.option_yaxis === AXIS_OPTIONS.MARGINAL) {
        this.$nextTick(() => {
          // Triggers after the next DOM update (after the component has been updated)
          this.toggleMargCond('t1', 't2');
        });
      }
    }
  },
  components: { ColocResultsTable, TraitLabel, VariantLabel, LzPlot, LDPanel },
  mixins: [ColocResultQuery],
  beforeCreate() {
    // Make constants available to component
    this.AXIS_OPTIONS = AXIS_OPTIONS;
    this.LD_OPTIONS = LD_OPTIONS;
  },
  beforeRouteEnter(to, from, next) {
    const filter_data = store.getters.getFilterData;
    getData(to.params.coloc_id, filter_data)
      .then(([this_result, nearby_results]) => next((vm) => {
        vm.setData(this_result, nearby_results);
      }));
  },
  beforeRouteUpdate(to, from, next) {
    this.loading = true;
    const filter_data = store.getters.getFilterData;
    getData(to.params.coloc_id, filter_data)
      .then(([this_result, nearby_results]) => {
        this.setData(this_result, nearby_results);
        next();
      });
  },
  methods: {
    addLDRef(item) {
      if (!this.ld_refs.includes(item)) this.ld_refs.push(item);
    },
    addPanelsFromIconClick(item) {
      const { signal1, signal2 } = item;
      this.addPanels(signal1, signal2);
    },
    addPanels(signal1, signal2) {
      if (this.$refs.lzregion) {
        this.$refs.lzregion.addPanelPair(signal1, signal2);
        this.addLDRef(signal1.lead_variant.vid);
        this.addLDRef(signal2.lead_variant.vid);
      }
    },
    dataTableRowClick(item) {
      const item_s2id = item.signal2.uuid;
      const row_s2id = this.signal2.uuid;
      if (row_s2id === item_s2id) return; // prevent trying to navigate to same page, Vue throws nasty error

      this.ld_refs.length = 0;
      this.$refs.lzregion.clearSignalList();
      this.$router.push({
        name: 'one_signal',
        params: {
          coloc_id: item.uuid
        }
      });
    },
    toggleMargCond(new_val, old_val) {
      this.$refs.lzregion.callPlot((plot) => {
        toggle_trait(plot.layout, 'assoc', old_val, new_val);
        plot.applyState();
      });

      this.$refs.lzcompare.callPlot((plot) => {
        toggle_trait(plot.layout, 'trait1', old_val, new_val);
        toggle_trait(plot.layout, 'trait2', old_val, new_val);
        plot.applyState();
      });
    },
    setData(this_result, nearby_results) {
      this.table_data = nearby_results.results;

      this.coloc_result_data = this_result;

      const { signal1, signal2 } = this_result;

      this.addLDRef(signal1.lead_variant.vid);
      this.addLDRef(signal2.lead_variant.vid);

      let variant = (this.option_ldchoice === LD_OPTIONS.SIGNAL1 ? signal1 : signal2).lead_variant.vid;
      variant = normalizeMarker(variant);

      const { start, end } = findPlotRegion(signal1.lead_variant.pos, signal2.lead_variant.pos);
      const initialState = {
        chr: signal1.lead_variant.chrom,
        // TODO add a variable for region size throughout app
        start,
        end,
        ldrefvar: variant,
      };

      const [s1_label, s1_color] = makePlotTitle(signal1);
      const [s2_label, s2_color] = makePlotTitle(signal2);

      this.layout_compare = get_compare_layout(s1_label, s2_label, initialState);

      const source_configs = get_region_sources(
        signal1.analysis.genome_build,
        url`/api/v1/signals/${signal1.uuid}/region/`,
        url`/api/v1/signals/${signal2.uuid}/region/`,
        url`/api/v1/ld/${signal1.analysis.ld}/region/`,
      );
      const sources = config_to_sources(source_configs);

      const layout_region = get_region_layout(
        { id: signal1.uuid, label: s1_label },
        { id: signal2.uuid, label: s2_label },
        initialState
      );

      // First attempt: this works, but am a little leary that trait1 may not always be panel[0], etc. I think because
      // of how the layout is constructed, it should be, but I'm not 100% sure.
      // layout_region.panels[0].title = { text: s1_label, style: { fill: s1_color } };
      // layout_region.panels[1].title = { text: s2_label, style: { fill: s2_color } };

      // In theory these could possibly work, but it seems as though LZ does not use a full jsonpath implementation.
      // We would have to include/import our own jsonpath library to do this. Keeping this for future reference.
      // LocusZoom.Layouts.mutate_attrs(
      //   layout_region,
      //   "$.panels[?(@.data_layers[?(@.namespace.assoc == 'trait1')] empty false)]",
      //   { text: s1_label, style: { fill: s1_color } });
      //
      // LocusZoom.Layouts.mutate_attrs(
      //   layout_region,
      //   "$.panels[?(@.data_layers[?(@.namespace.assoc == 'trait2')] empty false)]",
      //   { text: s2_label, style: { fill: s2_color } });

      function setPanelTitle(layout, title, color, trait) {
        layout.panels.forEach((panel) => {
          if (panel.data_layers) {
            panel.data_layers.forEach((layer) => {
              if (layer.namespace && layer.namespace.assoc === trait) {
                panel.title = { text: title, style: { fill: color } };
              }
            });
          }
        });
      }

      setPanelTitle(layout_region, s1_label, s1_color, 'trait1');
      setPanelTitle(layout_region, s2_label, s2_color, 'trait2');

      this.layout_region = layout_region;
      this.sources_region = () => sources; // getter function prevents LZ.DataSources from being wrapped as an observable when passed as a prop

      this.loading = false;
    },
    onLDRefChange(value) {
      const marker = normalizeMarker(value);
      this.$refs.lzcompare.callPlot((plot) => plot.applyState({ ldrefvar: marker }));
      this.$refs.lzregion.callPlot((plot) => plot.applyState({ ldrefvar: marker }));
    },
    onMargCondChange(value) {
      this.option_yaxis = value;
    },
  }
};
</script>

<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      class="mx-auto pa-md-3" type="table-tfoot, card"/>
    <v-container v-else :fluid="true">
      <v-row class="mb-2">
        <v-col cols="12">
          <h1>
            Colocalization of <trait-label class="indigo--text text--darken--4" :trait_data="coloc_result_data.signal1.analysis.trait"/>
            ({{ signal1.lead_variant.vid }})
            with <trait-label class="indigo--text text--darken--4" :trait_data="coloc_result_data.signal2.analysis.trait"/>
            ({{ signal2.lead_variant.vid }})
          </h1>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="6">
            <v-row justify="center">
                <lz-plot
                  ref="lzcompare"
                  :base_layout="layout_compare" :explicit_sources="sources_region" :show_loading="true"
                />
            </v-row>
            <v-row justify="end">
              <LDPanel
                  class="mr-12"
                  :ld_refs="ld_refs"
                  @ld-ref-changed="onLDRefChange"
                  @mc-changed="onMargCondChange"
                  ref="ldPanelRef"
              ></LDPanel>
          </v-row>
        </v-col>

        <v-col cols="6">
          <lz-plot
            ref="lzregion"
            :base_layout="layout_region" :explicit_sources="sources_region" :show_loading="true"/>
          <span v-if="signal1.neg_log_p === 'Infinity'">
            <span style="font-weight: bold; color: red;">⚠️</span>
            <span style="font-size: 10pt">The lead variant for <trait-label :trait_data="signal1.analysis.trait"/> has a p-value of 0, so the -log<sub>10</sub>p value is infinity.
            It cannot be displayed on the plot above.</span>
          </span>
          <span v-if="signal2.neg_log_p === 'Infinity'">
            <span style="font-weight: bold; color: red;">⚠️</span>
            <span style="font-size: 10pt">The lead variant for <trait-label :trait_data="signal2.analysis.trait"/> has a p-value of 0, so the -log<sub>10</sub>p value is infinity.
            It cannot be displayed on the plot above.</span>
          </span>
          <span v-if="signal1.cond_minp_variant && (signal1.lead_variant.vid !== signal1.cond_minp_variant)">
            <span class="text-caption">
              ⚠️ The lead variant <variant-label :variant="signal1.lead_variant.vid" size="x-small"/> shown for <trait-label :trait_data="signal1.analysis.trait" highlight/> is not
              the most significant variant for this conditionally distinct signal <variant-label :variant="signal1.cond_minp_variant" size="x-small"/>.
              For this trait, the published conditionally distinct variants were used to perform colocalization analysis. The SNP association results for this conditionally distinct signal
              may have been obtained using different software, parameters or LD panels than the published GWAS study.
            </span>
          </span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="mt-0">
          <h2>All colocalized signals in region</h2>
          <p>Colocalized signals within a 500kb window centered around the lead variants
            <variant-label :variant="signal1.lead_variant.vid" :build="signal1.analysis.genome_build" size="small"/> and
            <variant-label :variant="signal2.lead_variant.vid" :build="signal2.analysis.genome_build" size="small"/> from the originally selected colocalized signals
          </p>

          <coloc-results-table
            :items="table_data"
            :highlight-signals="[signal1, signal2]"
            :show_trait1="true"
            :abbrev_trait="true"
            :show_ensg="false"
            :options.sync="table_options"
            :server-items-length="table_result_count"
            :loading="loading_table"
            :showAddPlotIcon="true"
            @row-click="dataTableRowClick"
            @add-plots-from-icon="addPanelsFromIconClick"
          />

          <p style="margin-top: 5px">
            <span style="font-weight: bold">Bold</span> denotes the signals currently being shown in the plots above.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>

</style>
