import { url } from '@/util/util';

/**
 * Construct default API query URL
 * @return {module:url.URL} Returns a URL object that can be extended via `URL.searchParams.set(k, v)`
 */
function coloc_results_list() {
  return new URL(url`/api/v1/coloc/`, window.location.origin);
}

export { coloc_results_list };
