<script>
/**
 * Trait detail view (metadata and description of a single input. Shows trait detail, not colocalizations as such.)
 *
 * TODO: What else do we want in this view? Either fill in the page with something useful for QC, or else remove it entirely from routes table.
 */

import TraitLabel from '@/components/widgets/TraitLabel';
import { fetchJson, url } from '@/util/util';
import { ANALYSIS_TYPES } from '@/constants';

function getData(trait_id) {
  return fetchJson(url`/api/v1/traits/${trait_id}/`);
}

export default {
  name: 'TraitDetailView',
  data() {
    return {
      loading: true,
      page_data: {},
    };
  },
  computed: {
    is_gwas() {
      return this.page_data.trait_type === ANALYSIS_TYPES.GWAS;
    }
  },
  beforeRouteEnter (to, from, next) {
    getData(to.params.trait_id)
      // FIXME: catch errors/ handle route enter failure
      .then((page_data) => next((vm) => vm.setData(page_data)));
  },
  beforeRouteUpdate (to, from) {
    getData(to.params.trait_id)
      // FIXME: catch errors/ handle route enter failure
      .then((page_data) => this.setData(page_data));
  },
  methods: {
    setData(page_data) {
      this.loading = false;
      this.page_data = page_data;
    }
  },
  components: { TraitLabel }
};
</script>

<template>
  <v-container>
    <v-skeleton-loader v-if="loading"/>
    <div v-else>
      <h1>
        Trait details for: <trait-label :trait_data="page_data"/>
        <v-chip :color="is_gwas ? 'primary' : 'secondary'">{{ page_data.trait_type.toUpperCase() }}</v-chip>
      </h1>
      <strong>From: </strong> {{page_data.study_name}} (<em>{{page_data.authors}}</em>)
      <span v-if="page_data.pmid"> -  <a :href="`https://pubmed.ncbi.nlm.nih.gov/${page_data.pmid}/`">PubMed</a></span>
      <span v-if="page_data.external_link"> - (<a :href="page_data.external_link">details</a>)</span>
    </div>
  </v-container>
</template>

<style scoped></style>
