import { SAVED_FILTERS } from '@/constants';

const state = {
  panelIsVisible: true,
  buttonIsVisible: false,
  filterData: {
    selected_phenotypes: [],
    selected_tissues: [], // select from a list of all available QTL types
    selected_studies: [],
    selected_genes: [],
    near_region: '',
    filter_h4: 0.50,
    filter_r2: 0.30,
    filter_trait1_logp: 0.0,
    filter_trait2_logp: 0.0,
    show_ensg: false,
    show_effects: false
  }
};

const getters = {
  isFilterPanelVisible: (state) => state.panelIsVisible,
  isFilterButtonVisible: (state) => state.buttonIsVisible,
  getFilterData: (state) => state.filterData
};

const actions = {
  enableFilterPanel: ({ commit }) => { commit('enablePanel'); },
  disableFilterPanel: ({ commit }) => { commit('disablePanel'); },
  toggleFilterPanel: ({ commit }) => { commit('togglePanel'); },
  enableFilterButton: ({ commit }) => { commit('enableButton'); },
  disableFilterButton: ({ commit }) => { commit('disableButton'); },
  setFilterDataPoint: ({ commit }, filterDataPoint) => { commit('setFilter', filterDataPoint); },
};

const mutations = {
  enablePanel: (state) => { state.panelIsVisible = true; },
  disablePanel: (state) => { state.panelIsVisible = false; },
  togglePanel: (state) => { state.panelIsVisible = !state.panelIsVisible; },
  enableButton: (state) => { state.buttonIsVisible = true; },
  disableButton: (state) => { state.buttonIsVisible = false; },
  setFilter: (state, filterDataPoint) => {
    const key = filterDataPoint.dataKey;
    const value = filterDataPoint.dataValue;
    if (SAVED_FILTERS.includes(key)) {
      state.filterData[key] = value;
    }
  },
};

export default {
  state, getters, actions, mutations
};
