<script>
import { BIOMARKER_TYPES } from '@/constants';

/**
 * A simple reusable helper to show the name of a trait.
 *
 * This is useful because different types of traits will need to be rendered differently.
 */
export default {
  name: 'TraitLabel',
  props: {
    trait_data: { type: Object, required: true },
    abbrev: { type: Boolean, default: false },
    highlight: { type: Boolean, default: false }
  },
  created() {
    this.BIOMARKER_TYPES = BIOMARKER_TYPES;
  }
};
</script>

<style scoped>
  .ensg {
    color: #666666;
    font-size: 8pt;
  }
</style>

<template>
  <span v-if="trait_data.biomarker_type === BIOMARKER_TYPES.PHENOTYPE">
    <span v-if="abbrev">
      <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
      <span
        v-bind="attrs"
        v-on="on"
      >{{ trait_data.uuid }}</span>
      </template>
      <span>
        {{ trait_data.phenotype.name }}
      </span>
    </v-tooltip>
    </span>
    <span v-else :class="highlight ? 'indigo--text text--darken--4' : ''">
      {{ trait_data.phenotype.name }}
    </span>
  </span>
  <span v-else-if="trait_data.gene">
    <em>{{ trait_data.gene.symbol }}</em>
  </span>
</template>
