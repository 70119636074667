<template>
  <v-app>
    <v-app-bar app color="grey lighten-5" dark dense>
      <v-app-bar-nav-icon v-if="isFilterButtonVisible" @click="toggleFilterPanel" color="red"></v-app-bar-nav-icon>

      <AppLogo />

      <v-btn small color="blue lighten-2" class="mx-2">
        <router-link :to="{name: 'home'}">
          Home
        </router-link>
      </v-btn>
      <v-btn small color="blue lighten-2" class="mx-2">
        <router-link :to="{name: 'help'}">
          Help
        </router-link>
      </v-btn>
      <a href="https://forms.gle/9idPsYcUcKDBqS8s6" target="_blank" rel="noopener noreferrer">
        <v-btn small color="blue lighten-2" class="mx-2">
          Feedback
        </v-btn>
      </a>

      <v-spacer></v-spacer>

      <AmpLogo></AmpLogo>
    </v-app-bar>

    <v-main class="v-main">
      <v-container fluid>
        <router-view/>
      </v-container>
    </v-main>
    <PageFooter />
  </v-app>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import PageFooter from './components/widgets/PageFooter.vue';
import AppLogo from '@/components/widgets/AppLogo.vue';
import AmpLogo from '@/components/widgets/AmpLogo.vue';

export default {
  name: 'App',
  components: { AmpLogo, AppLogo, PageFooter },
  data: () => ({
  }),
  methods: {
    ...mapActions(['toggleFilterPanel'])
  },
  computed: {
    ...mapGetters(['isFilterButtonVisible'])
  }
};
</script>

<style scoped>
.v-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the top */
  overflow: auto; /* Add scrolling if content overflows */
  min-height: calc(100vh - 50px); /* Adjust the 50px to the height of your footer */
}

.v-btn a {
  text-decoration: none;
  color: inherit;
}

</style>
