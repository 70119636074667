const PORTALDEV_API_URL = 'https://portaldev.sph.umich.edu/api/v1/';

// Should match the enumeration values in the backend API
const ANALYSIS_TYPES = Object.freeze({
  GWAS: 'gwas',
  EQTL: 'eqtl'
});

const BIOMARKER_TYPES = Object.freeze({
  PHENOTYPE: 'phenotype',
  GENE_EXPRESSION: 'gene-expression',
  EXON_EXPRESSION: 'exon-expression',
});

// The distance used for all "things near entity" queries
const NEARBY_DIST = 500e3;

// Colors used for effect size direction
const COLOR_EFF_POS = '#fc8d59';
const COLOR_EFF_NEG = '#91bfdb';
const COLOR_EFF_ZERO = '#A9A9A9';
const COLOR_EFF_NA = '#ffffff';

const SAVED_FILTERS = ['filter_h4', 'filter_r2', 'filter_trait1_logp', 'filter_trait2_logp',
  'selected_genes', 'selected_phenotypes', 'near_region', 'selected_tissues', 'selected_studies'];

const ITEMS_PER_PAGE = 200;
const POS_DEC_REGEX = /^[0-9]*\.?[0-9]*$/;

export { PORTALDEV_API_URL, NEARBY_DIST, ANALYSIS_TYPES, BIOMARKER_TYPES, COLOR_EFF_POS, COLOR_EFF_NEG, COLOR_EFF_ZERO, COLOR_EFF_NA,
  SAVED_FILTERS, ITEMS_PER_PAGE, POS_DEC_REGEX };
