import { fetchJson } from '@/util/util';
import { coloc_results_list } from '@/util/urls';

/**
 * Mixin that enables querying colocalization results. Used in conjunction with `ColocResultsTable`
 *
 * Many views show a table of signals (whether genome wide or in a region). That table can be sorted or filtered in ways that
 */
export default {
  data() {
    return {
      // Configure search result table, including parameters that are used to control query
      table_data: [],
      table_options: { sortBy: [], sortDesc: [], page: 1 },
      loading_table: false,
      table_result_count: 0,
    };
  },
  computed: {
    table_url() {
      // Individual pages will probably override this based on additional filters (region, trait, etc)
      return coloc_query_url.bind(this)();
    }
  },
  watch: {
    table_url() {
      // Re-fetch page data every time search URL changes
      this.fetchTableData();
    }
  },
  methods: {
    // This method can't be used in route guards, but an inherent assumption is that we are comfortable letting the
    //   rest of the page load, then rendering the table asynchronously as data arrives
    fetchTableData() {
      // Todo handle errors- display etc
      this.loading_table = true;

      return fetchJson(this.table_url)
        .then((resp) => {
          this.table_data = resp.results;
          this.table_result_count = resp.count;
        }).finally(() => {
          this.loading_table = false;
        });
    }
  }
};

/**
 * Most users of this mixin will want to build on the basic url with additional query params, so this function is exported as a helper
 *   To use this method in a mixin class, use `coloc_query_url.bind(this)(params)`
 *
 *   If used as a standalone method (like before the route is loaded), it will always assume we are fetching default table options (page 1 etc)
 * @return {module:url.URL}
 */
function coloc_query_url() {
  const base = coloc_results_list();
  const { table_options = {} } = this || {};
  const { page = 1, sortBy = [], sortDesc = [] } = table_options;

  // Pagination is entirely handled by the API and table can notify what data to show
  if (page !== 1) {
    base.searchParams.set('page', page);
  }

  /* eslint-disable quote-props */
  const translate = {
    'coloc_h4': 'h4',
    'r2': 'r2',
    'n_coloc_between_traits': 'n_coloc_between_traits',
    'signal1.neg_log_p': 'signal1_logp',
    'signal2.neg_log_p': 'signal2_logp',
    'signal1.lead_variant.chrom': 'signal1_chrom',
    'signal1.lead_variant.pos': 'signal1_pos',
    'signal1.analysis.trait.uuid': 'signal1_trait',
    'signal2.analysis.trait.uuid': 'signal2_gene_symbol',
    'signal2.lead_variant.chrom': 'signal2_chrom',
    'signal2.lead_variant.pos': 'signal2_pos',
    'signal1.analysis.trait.gene.ens_id': 'signal1_gene_ens_id',
    'signal1.analysis.trait.gene.symbol': 'signal1_gene_symbol',
    'signal1.analysis.tissue': 'signal1_tissue',
    'signal1.analysis.trait.exon.ens_id': 'signal1_exon_ens_id',
    'signal2.analysis.trait.gene.ens_id': 'signal2_gene_ens_id',
    'signal2.analysis.trait.gene.symbol': 'signal2_gene_symbol',
    'signal2.analysis.tissue': 'signal2_tissue',
    'signal2.analysis.trait.exon.ens_id': 'signal2_exon_ens_id',
    'signal1.analysis.study.uuid': 'signal1_study',
    'signal2.analysis.study.uuid': 'signal2_study',
  };
  /* eslint-enable quote-props */

  const newOrdering = [];
  for (const [i, e] of sortBy.entries()) {
    const sort_order = sortDesc[i] ? '-' : '';
    if (e.includes('lead_variant')) {
      const signal = e.includes('signal1') ? 'signal1' : 'signal2';
      const ordering = `${sort_order}${signal}_chrom,${sort_order}${signal}_pos`;
      newOrdering.push(ordering);
    } else {
      const sort_field = translate[e] || e;
      newOrdering.push(`${sort_order}${sort_field}`);
    }
  }

  if (newOrdering.length > 0) {
    base.searchParams.set('ordering', newOrdering.join(','));
  }

  return base;
}

export { coloc_query_url };
